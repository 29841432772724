import React from 'react';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Popover from './Popover';
import TableFilter from './TableFilter';
import TableViewCol from './TableViewCol';
import TableSearch from './TableSearch';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import PrintIcon from '@mui/icons-material/Print';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import FilterIcon from '@mui/icons-material/FilterList';
import ReactToPrint from 'react-to-print';
import { find } from 'lodash';
import { withStyles } from '@mui/styles';
import { createCSVDownload } from '../utils';
import moment from 'moment';
import { Label } from 'reactstrap';
import { Col, Row } from 'reactstrap';
import { DatePicker } from '@mui/x-date-pickers';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { FormControl } from '@mui/material';
import Button from '@mui/material/Button';
export const defaultToolbarStyles = theme => ({
  root: {},
  left: {
    flex: '1 1 auto',
  },
  actions: {
    flex: '1 1 auto',
    textAlign: 'right',
  },
  titleRoot: {},
  titleText: {},
  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  iconActive: {
    color: theme.palette.primary.main,
  },
  filterPaper: {
    maxWidth: '50%',
  },
  searchIcon: {
    display: 'inline-flex',
    marginTop: '10px',
    marginRight: '8px',
  },
  [theme.breakpoints.down('sm')]: {
    titleRoot: {},
    titleText: {
      fontSize: '16px',
    },
    spacer: {
      display: 'none',
    },
    left: {
      // flex: "1 1 40%",
      padding: '8px 0px',
    },
    actions: {
      // flex: "1 1 60%",
      textAlign: 'right',
    },
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      display: 'block',
    },
    left: {
      padding: '8px 0px 0px 0px',
    },
    titleText: {
      textAlign: 'center',
    },
    actions: {
      textAlign: 'center',
    },
  },
  '@media screen and (max-width: 480px)': {},
});

class TableToolbar extends React.Component {
  state = {
    iconActive: null,
    showSearch: Boolean(this.props.searchText || this.props.options.searchText || this.props.options.searchOpen),
    searchText: this.props.searchText || null,
    open: false,
    openFilter: false,
    status: '',
    seller_id: '',
    from: moment().subtract(1, 'months'),
    to: moment().format(),
    type: ''
  };

  componentDidUpdate(prevProps) {
    if (this.props.searchText !== prevProps.searchText) {
      this.setState({ searchText: this.props.searchText });
    }
  }

  handleCSVDownload = () => {
    const { data, displayData, columns, options } = this.props;
    let dataToDownload = data;
    let columnsToDownload = columns;

    if (options.downloadOptions && options.downloadOptions.filterOptions) {
      // check rows first:
      if (options.downloadOptions.filterOptions.useDisplayedRowsOnly) {
        dataToDownload = displayData.map((row, index) => {
          let i = -1;

          // Help to preserve sort order in custom render columns
          row.index = index;

          return {
            data: row.data.map(column => {
              i += 1;

              // if we have a custom render, which will appear as a react element, we must grab the actual value from data
              // that matches the dataIndex and column
              // TODO: Create a utility function for checking whether or not something is a react object
              return typeof column === 'object' && column !== null && !Array.isArray(column)
                ? find(data, d => d.index === row.dataIndex).data[i]
                : column;
            }),
          };
        });
      }

      // now, check columns:
      if (options.downloadOptions.filterOptions.useDisplayedColumnsOnly) {
        columnsToDownload = columns.filter((_, index) => _.display === 'true');

        dataToDownload = dataToDownload.map(row => {
          row.data = row.data.filter((_, index) => columns[index].display === 'true');
          return row;
        });
      }
    }
    createCSVDownload(columnsToDownload, dataToDownload, options);
  };

  handlePopUp = () => {
    this.setState({ openFilter: true })
  }
  handleSettelMent = async () => {
    this.props.handleSettelMent()
  }
  // applyFilter = async() => {
  //   window.open(`${AppConfig.baseUrl}order/all_order_csv_data?token=${localStorage.getItem('admin_token')}&from_date=${this.state.from ? moment(this.state.from).format('YYYY-MM-DD'): moment().format()}&to_date=${this.state.to ? moment(this.state.to).format('YYYY-MM-DD') : moment().format()} `, '_blank');
  //       this.setState({ 
  //         openFilter: false,
  //         status:'',
  //         seller_id:''
  //       })
  // } 

  handleRequestClose = () => {
    this.setState({
      openFilter: false,
      status: '',
      seller_id: '',
      from_date: '',
      to_date: '',
      from: moment().format(),
      to: moment().format()
    });
  }
  handleDateChange = (date, key) => {
    this.setState({ [key]: date })
  }
  handleChanged = (e, key) => {
    this.setState({ [key]: e.target.value });
  }

  setActiveIcon = iconName => {
    this.setState(
      prevState => ({
        showSearch: this.isSearchShown(iconName),
        iconActive: iconName,
        prevIconActive: prevState.iconActive,
      }),
      () => {
        const { iconActive, prevIconActive } = this.state;

        if (iconActive === 'filter') {
          this.openPopOver('iconActive')
          this.props.setTableAction('onFilterDialogOpen');
          if (this.props.options.onFilterDialogOpen) {

            this.props.options.onFilterDialogOpen();
          }
        }
        if (iconActive === undefined && prevIconActive === 'filter') {
          this.openPopOver('prevIconActive')
          this.props.setTableAction('onFilterDialogClose');
          if (this.props.options.onFilterDialogClose) {

            this.props.options.onFilterDialogClose();
          }
        }
      },
    );
  };
  openPopOver = (val) => {
    this.setState({ open: val === 'prevIconActive' ? false : true })
  }
  isSearchShown = iconName => {
    let nextVal = false;
    if (this.state.showSearch) {
      if (this.state.searchText) {
        nextVal = true;
      } else {
        const { onSearchClose } = this.props.options;
        this.props.setTableAction('onSearchClose');
        if (onSearchClose) onSearchClose();
        nextVal = false;
      }
    } else if (iconName === 'search') {
      nextVal = this.showSearch();
    }
    return nextVal;
  };

  getActiveIcon = (styles, iconName) => {
    return this.state.iconActive !== iconName ? styles.icon : styles.iconActive;
  };

  showSearch = () => {
    this.props.setTableAction('onSearchOpen');
    !!this.props.options.onSearchOpen && this.props.options.onSearchOpen();
    return true;
  };

  hideSearch = () => {
    const { onSearchClose } = this.props.options;

    this.props.setTableAction('onSearchClose');
    if (onSearchClose) onSearchClose();
    this.props.searchClose();

    this.setState(() => ({
      iconActive: null,
      showSearch: false,
      searchText: null,
    }));
  };

  handleSearch = value => {
    this.setState({ searchText: value });

  };
  applySearch = () => {
    this.props.searchTextUpdate(this.state.searchText);
  }

  render() {
    const {
      data,
      options,
      classes,
      columns,
      filterData,
      filterList,
      filterUpdate,
      resetFilters,
      toggleViewColumn,
      title,
      tableRef,
    } = this.props;
    let { month, year, yearList, type, seller_id, selectedDate, from, to } = this.state;


    const { search, downloadCsv, print, viewColumns, filterTable } = options.textLabels.toolbar;
    const { showSearch, searchText } = this.state;

    return (
      <Toolbar className={classes.root} role={'toolbar'} aria-label={'Table Toolbar'}>


        {/* {"₹" + (dataOne.order_value).toLocaleString('en-IN',{maximumFractionDigits:2,minimumFractionDigits:2})} */}
        <div className={classes.left}>
          {
            this.props && this.props.identifier && (this.props.identifier == 'allOrderListing' || this.props.identifier[0] == 'allOrderListing') && this.props.isAdmin == 'admin' ?
              <div style={{ marginTop: '10px', color: 'blue' }}><h3 > Total Amount : ₹ {(this.props.amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}  </h3></div> : ''
          }
          {showSearch === true ? (
            options.customSearchRender ? (
              options.customSearchRender(searchText, this.handleSearch, this.hideSearch, options)
            ) : (
              <TableSearch
                searchText={searchText}
                onSearch={this.handleSearch}
                onHide={this.hideSearch}
                options={options}
                applySearch={this.applySearch}
              />
            )
          ) : typeof title !== 'string' ? (
            title
          ) : (
            <div className={classes.titleRoot} aria-hidden={'true'}>
              <Typography variant="h6" className={classes.titleText}>
                {title}
              </Typography>
            </div>
          )}
        </div>
        <div className={classes.actions}>
          {options.search && (
            <Tooltip title={search} disableFocusListener>
              <IconButton
                aria-label={search}
                data-testid={search + '-iconButton'}
                buttonRef={el => (this.searchButton = el)}
                classes={{ root: this.getActiveIcon(classes, 'search') }}
                onClick={this.setActiveIcon.bind(null, 'search')}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
          )}
          {options.download && (
            <Tooltip title={downloadCsv}>
              <IconButton
                data-testid={downloadCsv + '-iconButton'}
                aria-label={downloadCsv}
                classes={{ root: classes.icon }}
                onClick={this.handleCSVDownload}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          )}
          {options.print && (
            <span>
              <ReactToPrint
                trigger={() => (
                  <span>
                    <Tooltip title={print}>
                      <IconButton
                        data-testid={print + '-iconButton'}
                        aria-label={print}
                        classes={{ root: classes.icon }}>
                        <PrintIcon />
                      </IconButton>
                    </Tooltip>
                  </span>
                )}
                content={() => this.props.tableRef()}
              />
            </span>
          )}
          {options.viewColumns && (
            <Popover
              refExit={this.setActiveIcon.bind(null)}
              trigger={
                <Tooltip title={viewColumns} disableFocusListener>
                  <IconButton
                    data-testid={viewColumns + '-iconButton'}
                    aria-label={viewColumns}
                    classes={{ root: this.getActiveIcon(classes, 'viewcolumns') }}
                    onClick={this.setActiveIcon.bind(null, 'viewcolumns')}>
                    <ViewColumnIcon />
                  </IconButton>
                </Tooltip>
              }
              content={
                <TableViewCol data={data} columns={columns} options={options} onColumnUpdate={toggleViewColumn} />
              }
            />
          )}
          {options.filter && (
            <Popover
              refExit={this.setActiveIcon.bind(null)}
              classes={{ paper: classes.filterPaper }}
              setIconActive={this.setActiveIcon.bind(null, 'filter')}
              open={this.state.open}
              trigger={
                <Tooltip title={filterTable} disableFocusListener>
                  <IconButton
                    data-testid={filterTable + '-iconButton'}
                    aria-label={filterTable}
                    classes={{ root: this.getActiveIcon(classes, 'filter') }}
                    onClick={this.setActiveIcon.bind(null, 'filter')}>
                    <FilterIcon />
                  </IconButton>
                </Tooltip>
              }
              content={
                <TableFilter
                  customFooter={options.customFilterDialogFooter}
                  columns={columns}
                  options={options}
                  filterList={filterList}
                  filterData={filterData}
                  onFilterUpdate={filterUpdate}
                  onFilterReset={resetFilters}
                  setActiveIcon={this.setActiveIcon.bind(null)}
                />
              }
            />
          )}
          {options.customToolbar && options.customToolbar()}
        </div>
        {this.props && this.props.identifier && (this.props.identifier == 'allSettlement' || this.props.identifier[1] == 'allSettlements') && (
          <Tooltip title={downloadCsv}>
            <IconButton
              data-testid={downloadCsv + '-iconButton'}
              aria-label={downloadCsv}
              classes={{ root: classes.icon }}
              onClick={this.handleSettelMent}>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        )}
        {/* { 
         this.props&& this.props.identifier&&(this.props.identifier[1]=='allSettlements') &&
         <Tooltip title={downloadCsv}>
              <IconButton
                data-testid={downloadCsv + '-iconButton'}
                aria-label={downloadCsv}
                classes={{ root: classes.icon }}
                onClick={()=>{this.setState({openFilter: true})}}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>} */}
        <Dialog open={this.state.openFilter} onClose={this.handleRequestClose}>
          <DialogContent >

            <Row form >
              <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                <FormControl className="w-100 mb-2 ">
                  <Label for="fromDate">FROM</Label>
                  <DatePicker
                    onChange={(date) => this.handleDateChange(date, 'from')}
                    name='from'
                    id="from"
                    value={from}
                    // autoOk
                    fullWidth
                    leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                    rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                    format="DD/MM/YYYY"
                  />
                </FormControl>
              </Col>

              <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                <FormControl >
                  <Label for="toDate">TO</Label>
                  <DatePicker
                    onChange={(date) => this.handleDateChange(date, 'to')}
                    name='to'
                    id="to"
                    value={to}
                    // autoOk
                    fullWidth
                    leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                    rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                    format="DD/MM/YYYY"
                  />
                </FormControl>
              </Col>
            </Row>

            <Row>

            </Row>
            <DialogActions>
              <Button onClick={() => this.handleRequestClose()} color='secondary'>Cancel</Button>
              <Button onClick={() => this.applyFilter()} color='primary'>Download</Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

      </Toolbar>


    );
  }
}

export default withStyles(defaultToolbarStyles, { name: 'MUIDataTableToolbar' })(TableToolbar);
