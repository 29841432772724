import React from "react";
import JumboLayout from "@jumbo/components/JumboLayout";
import Header from "../shared/headers/Header";
import Sidebar from "../shared/sidebars/Sidebar";
import Footer from "../shared/footers/Footer";
import useJumboLayout from "@jumbo/hooks/useJumboLayout";
import layoutConfig from "./layoutConfig";

const VerticalDefault = ({ children }) => {
    const { setJumboLayoutOptions } = useJumboLayout();


    React.useEffect(() => {
        setJumboLayoutOptions(layoutConfig)
    }, []);


    return (
        <JumboLayout
            header={<Header />}
            sidebar={<Sidebar />}
            footer={<Footer />}
            headerSx={{
                height: 80,
            }}
        >
            {children}
        </JumboLayout>
    );
};

export default VerticalDefault;
